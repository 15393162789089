<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-between"
      >
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
        >
          {{ $t('публиковать') }}
        </b-button> -->
        <div>
          <h3 class="ml-2">
            {{ GET_ONE_COURSE.title }}
          </h3>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addTheme(null)"
        >
          <feather-icon
            icon="PlusIcon"
          />
          {{ $t('general.add') }}
        <!--Добавить button-->
        </b-button>
      </b-col>
      <b-col cols="12">
        <draggable
          v-model="list"
          class="list-group list-group-flush cursor-move mt-2"
          tag="ul"
          @change="savePosition($event, true)"
        >
          <div
            v-for="listItem in list"
            :key="`key-${listItem.id}`"
          >
            <b-list-group-item
              v-b-toggle="`coll${listItem.id}`"
              tag="li"
              class="drag-style"
            >
              <div class="ml-25">
                <b-card-text class="font-weight-bold mb-0 d-flex drag-text">
                  <div>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="info"
                      class="btn-icon mr-50 p-25"
                      size="sm"
                      @click.stop="addTheme(listItem.position)"
                    >
                      <feather-icon
                        icon="ArrowDownIcon"
                        size="22"
                      />
                    </b-button>
                  </div>
                  <h5 class="text-uppercase p-0 m-0">
                    {{ listItem.title }}
                  </h5>
                  <div class="d-flex align-items-center no-wrap">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="warning"
                      class="btn-icon mr-50 p-25"
                      size="sm"
                      @click.stop="editTheme(listItem)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="22"
                      />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon p-25"
                      size="sm"
                      @click.stop="deleteTheme(listItem.id)"
                    >
                      <feather-icon
                        size="22"
                        icon="Trash2Icon"
                      />
                    </b-button>
                  </div>
                </b-card-text>
              </div>
            </b-list-group-item>
            <b-collapse
              :id="`coll${listItem.id}`"
              :visible="true"
            >
              <b-card class="mx-2">
                <b-row>
                  <b-col
                    class="text-right mb-1"
                    cols="12"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="addLesson(listItem.id, null)"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                      {{ $t('courses.add_lesson') }}
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <dragg-table
                      v-model="listItem.lessons"
                      @edit="editLesson"
                      @addLesson="addLesson(listItem.id, $event)"
                      @delete="deleteLesson"
                      @addContent="(id) => $router.push(`/course/course-administrations/theme-list/${$route.params.id}/${id}`)"
                      @change="savePosition($event, false)"
                    />
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </div>
        </draggable>
      </b-col>
    </b-row>
    <defoult-add-modal
      id="AddEditTheme"
      :edit-title="$t(titleModaleEdit)"
      :add-title="$t(titleModaleAdd)"
      :edit-data="dataToEdit"
      :is-edit="true"
      :no-lang="true"
      @add-button="saveTheme"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BListGroupItem,
  BCardText,
  BCollapse,
  VBToggle,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import DefoultAddModal from '@/components/DefoultAddModal.vue'
import DraggTable from '@/components/DraggTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    DefoultAddModal,
    BCardText,
    draggable,
    BListGroupItem,
    BCard,
    BCollapse,
    DraggTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      lessonData: {},
      editedTheme: {},
      lessonParentId: null,
      isEdit: false,
      isChapter: true,
      table: [],
      chapter: null,
      dataToEdit: {},
      titleModaleEdit: 'courses.edit_theme',
      titleModaleAdd: 'courses.add_theme',
    }
  },
  computed: {
    ...mapGetters('courses', ['GET_ONE_COURSE', 'GET_COURSE_CHAPTERS']),
    list: {
      get() {
        return this.GET_COURSE_CHAPTERS
      },
      set(val) {
        this.SET_CHAPTERS(val)
      },
    },
  },
  mounted() {
    this.FETCH_ONE_COURSE(this.$route.params.id).then(() => {
      this.updateBreadcrumb()
      this.list = this.GET_COURSE_CHAPTERS
    })
  },
  methods: {
    ...mapActions('courses', ['FETCH_ONE_COURSE', 'CREATE_CHAPTER', 'UPDATE_CHAPTER', 'DELETE_CHAPTER', 'UPDATE_LESSON', 'DELETE_LESSON', 'CREATE_LESSON']),
    ...mapMutations('breadcrumb', ['UPDATE_BREADCRUMB']),
    ...mapMutations('courses', ['SET_CHAPTERS']),

    // Chapter
    editTheme(item) {
      this.titleModaleAdd = 'courses.add_theme'
      this.isEdit = true
      this.isChapter = true
      this.dataToEdit = item
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    addTheme(defPosition) {
      this.titleModaleEdit = 'courses.edit_theme'
      if (defPosition !== null) {
        this.dataToEdit = {
          title: '',
          position: defPosition + 1,
        }
      } else {
        this.dataToEdit = {
          title: '',
        }
      }
      this.isChapter = true
      this.isEdit = false
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    saveTheme(data) {
      if (this.isChapter) {
        if (!this.isEdit) {
          const savedData = { course: this.$route.params.id, ...data }
          this.CREATE_CHAPTER(savedData)
            .then(() => {
              this.$_successToast(this.$t('general.added'))
              this.FETCH_ONE_COURSE(this.$route.params.id)
              this.$bvModal.hide('AddEditTheme')
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        } else {
          this.UPDATE_CHAPTER(data)
            .then(() => {
              this.$_successToast(this.$t('general.edited'))
              this.FETCH_ONE_COURSE(this.$route.params.id)
              this.$bvModal.hide('AddEditTheme')
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      } else
      if (!this.isEdit) {
        const ReturnData = data
        ReturnData.chapter = this.chapter
        this.CREATE_LESSON(ReturnData)
          .then(() => {
            this.$_successToast(this.$t('general.added'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
            this.$bvModal.hide('AddEditTheme')
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      } else {
        this.UPDATE_LESSON(data)
          .then(() => {
            this.$_successToast(this.$t('general.edited'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
            this.$bvModal.hide('AddEditTheme')
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
          })
      }
    },
    deleteTheme(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_CHAPTER(id)
            .then(() => {
              this.$_successToast(this.$t('general.deleted'))
              this.FETCH_ONE_COURSE(this.$route.params.id)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    // lesson
    addLesson(id, defPosition) {
      this.titleModaleAdd = 'courses.add_lesson'
      if (defPosition !== null) {
        this.dataToEdit = {
          title: '',
          position: defPosition + 1,
        }
      } else {
        this.dataToEdit = {
          title: '',
        }
      }
      this.chapter = id
      this.isChapter = false
      this.isEdit = false
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    editLesson(val) {
      this.titleModaleEdit = 'courses.edit_lesson'
      this.isEdit = true
      this.dataToEdit = val
      this.isChapter = false
      this.$nextTick(() => {
        this.$bvModal.show('AddEditTheme')
      })
    },
    deleteLesson(id) {
      this.$_showAreYouSureModal().then(value => {
        if (value) {
          this.DELETE_LESSON(id)
            .then(() => {
              this.$_successToast(this.$t('general.deleted'))
              this.FETCH_ONE_COURSE(this.$route.params.id)
            })
            .catch(() => {
              this.$_errorToast(this.$t('general.error'))
            })
        }
      })
    },
    // others
    savePosition(event, type) {
      const data = { id: event.moved.element.id, position: event.moved.newIndex }
      if (type) {
        this.UPDATE_CHAPTER(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
          })
      } else {
        this.UPDATE_LESSON(data)
          .then(() => {
            this.$_successToast(this.$t('general.saved'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
          })
          .catch(() => {
            this.$_errorToast(this.$t('general.error'))
            this.FETCH_ONE_COURSE(this.$route.params.id)
          })
      }
    },
    updateBreadcrumb() {
      const [first, second] = this.$_removeObjectConnection(this.$route.meta.breadcrumb)
      second.text = this.GET_ONE_COURSE.title
      this.UPDATE_BREADCRUMB([first, second])
    },
  },
}
</script>

<style>
.drag-style {
      padding: 1rem 2.8rem 1rem 1rem;
      margin-bottom: 1rem;
}
.drag-text {
    justify-content: space-between;
    align-items: center;
}
</style>
