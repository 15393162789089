<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          v-for="i in fields"
          :key="i.key"
          scope="col"
          :class="i.thClass"
        >
          {{ $t(i.label) }}
        </th>
      </tr>
    </thead>
    <draggable
      v-model="Vmodel"
      tag="tbody"
      @input="onInput"
      @change="$emit('change', $event)"
    >
      <tr
        v-for="item in Vmodel"
        :key="`tablle${item.id}`"
      >
        <td>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="btn-icon mr-50 p-25"
            size="sm"
            @click="$emit('addLesson', item.position)"
          >
            <feather-icon
              icon="ArrowDownIcon"
              size="22"
            />
          </b-button>
        </td>
        <td scope="row">
          {{ item.title }}
        </td>
        <td>
          {{ $t('courses.content') }} {{ item.type }}
        </td>
        <td>
          <div class="d-flex align-items-center justify-content-center no-wrap">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click.stop="$emit('addContent', item.id)"
            >
              <feather-icon
                icon="FilePlusIcon"
                size="22"
              />
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-50 p-25"
              size="sm"
            >
              <feather-icon
                icon="ClipboardIcon"
                size="22"
              />
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$emit('edit', item)"
            >
              <feather-icon
                icon="EditIcon"
                size="22"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="$emit('delete', item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </td>
      </tr>
    </draggable>
  </table>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    draggable,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => ([]),
    },
  },
  data() {
    return {
      Vmodel: this.value,
      fields: [
        {
          label: 'general.add',
          key: 'index',
        },
        {
          label: 'courses.lesson_title',
          key: 'title',
        },
        {
          label: 'courses.type',
          key: 'type',
        },
        {
          label: 'general.actions',
          key: 'actions',
          thClass: 'text-center',
        },
      ],
    }
  },
  watch: {
    value(newVal) {
      this.Vmodel = newVal
    },
  },
  methods: {
    onInput() {
      this.$emit('input', this.value)
    },
  },
}
</script>

<style>

</style>
